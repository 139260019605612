import React,{useState, useEffect} from "react";
import ReactApexChart from "react-apexcharts";
import BACKEND_DOMAIN from "../../../service";
import { useSelector } from "react-redux";
import axios from "axios";

const SpakChart1 = () => {
    const series = [
        {
            data: [23, 32, 7, 38, 27, 32, 27, 34, 26, 31, 28],
        },
    ];
    const options = {
        chart: {
            type: "line",
            width: 80,
            height: 35,
            sparkline: {
                enabled: true,
            },
        },
        stroke: {
            width: [3],
            curve: "smooth",
        },
        colors: ["#5664d2"],

        tooltip: {
            fixed: {
                enabled: false,
            },
            x: {
                show: false,
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return "";
                    },
                },
            },
            marker: {
                show: false,
            },
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart options={options} series={series} type="line" height={35} width={80} />
        </React.Fragment>
    );
};

const SpakChart2 = () => {
    const series = [
        {
            data: [24, 62, 42, 84, 63, 25, 44, 46, 54, 28, 54],
        },
    ];

    const options = {
        chart: {
            type: "line",
            width: 80,
            height: 35,
            sparkline: {
                enabled: true,
            },
        },
        stroke: {
            width: [3],
            curve: "smooth",
        },
        colors: ["#5664d2"],
        tooltip: {
            fixed: {
                enabled: false,
            },
            x: {
                show: false,
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return "";
                    },
                },
            },
            marker: {
                show: false,
            },
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart options={options} series={series} type="line" height={35} width={80} />
        </React.Fragment>
    );
};

const SpakChart3 = () => {
    const series = [
        {
            data: [42, 31, 42, 34, 46, 38, 44, 36, 42, 32, 54],
        },
    ];

    const options = {
        chart: {
            type: "line",
            width: 80,
            height: 35,
            sparkline: {
                enabled: true,
            },
        },
        stroke: {
            width: [3],
            curve: "smooth",
        },
        colors: ["#5664d2"],
        tooltip: {
            fixed: {
                enabled: false,
            },
            x: {
                show: false,
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return "";
                    },
                },
            },
            marker: {
                show: false,
            },
        },
    };

    return (
        <React.Fragment>
            <ReactApexChart options={options} series={series} type="line" height={35} width={80} />
        </React.Fragment>
    );
};

// const RevenueAnalyticsChart = () => {
//     const token = useSelector((state) => state.Login.token);
// const [revedata, setRevenueData]= useState([])


   
//     const series = [
//         {
//             name: "Credit",
//             type: "column",
//             data: [],
//         },
//         {
//             name: "Debit",
//             type: "column",
//             data: [],
//         },
//     ];

//     const options = {
//         chart: {
//             toolbar: {
//                 show: false,
//             },
//         },
//         stroke: {
//             width: [0, 3],
//             curve: "smooth",
//         },
//         plotOptions: {
//             bar: {
//                 horizontal: false,
//                 columnWidth: "60%",
//             },
//         },
//         dataLabels: {
//             enabled: false,
//         },

//         legend: {
//             show: false,
//         },
//         colors: ["#5664d2", "#1cbb8c"],
//         labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
//     };

//     return (
//         <React.Fragment>
//             <ReactApexChart options={options} series={series} type="line" height={280} />
//         </React.Fragment>
//     );
// };

const RevenueAnalyticsChart = () => {
    const token = useSelector((state) => state.Login.token);
    const [series, setSeries] = useState([
        {
            name: "Credit",
            type: "column",
            data: [],
        },
        {
            name: "Debit",
            type: "column",
            data: [],
        },
    ]);

    const fetchData = async () => {
        try {
          
            const revenueApi = `${BACKEND_DOMAIN}/wallets/merchant/dashboard/revenue/graph/data`;
          
            const response = await axios.get(revenueApi, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = response.data.data;

            console.log("graph data ",data);

            // Define the months in the correct order
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

            // Map the data to the correct month order
            const creditData = months.map(month => data[month]?.credit ?? 0);
            const debitData = months.map(month => data[month]?.debit ?? 0);

            setSeries([
                {
                    name: "Credit",
                    type: "column",
                    data: creditData,
                },
                {
                    name: "Debit",
                    type: "column",
                    data: debitData,
                },
            ]);
        } catch (error) {
            console.error("Error fetching revenue data:", error.message);
        }
    };

    useEffect(() => {
        if (token) {
            fetchData();
        }
    }, [token]);

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        stroke: {
            width: [0, 3],
            curve: "smooth",
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "40%",
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false,
        },
        colors: ["#5664d2", "#1cbb8c"],
        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    };

    return (
        <React.Fragment>
        <div className="chart-container">
            <ReactApexChart options={options} series={series} type="line" height={280} />
        </div>
    </React.Fragment>
    );
};

export { SpakChart1, SpakChart2, SpakChart3, RevenueAnalyticsChart };
