import React, { useMemo, useEffect, useState } from "react";
import TableContainer from "../../../../components/Common/TableContainer";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Card, CardBody, Container,Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BACKEND_DOMAIN from "../../../../service";

const MerchantSubCategories = () => {
    const [subcategories, setSubCategories] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [subcategoryIdToDelete, setSubcategoryIdToDelete] = useState(null);
    const [deletionSuccessMessage, setDeletionSuccessMessage] = useState("");
    const [isSubCategoriesListEmpty, setIsSubCategoriesListEmpty] = useState(false);

    const authToken = useSelector((state) => state.Login.token);

    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const deleteSubcategory = (subcategoryId) => {
        setSubcategoryIdToDelete(subcategoryId);
        toggleDeleteModal();
    };

    const confirmDelete = () => {
        if (subcategoryIdToDelete) {
            const apiUrlDelete = `${BACKEND_DOMAIN}/business/merchant/subcategories/${subcategoryIdToDelete}/delete/`;

            fetch(apiUrlDelete, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.ok) {
                        const updatedSubCategories = subcategories.filter(
                            (subcategory) => subcategory.id !== subcategoryIdToDelete
                        );
                        setSubCategories(updatedSubCategories);
                        setDeletionSuccessMessage("Sub-category has been deleted successfully!");
                        setTimeout(() => {
                            setDeletionSuccessMessage("");
                        }, 3000);
                    } else {
                        console.error("Error deleting subcategory:", response.status);
                    }
                })
                .catch((error) => console.error("Error deleting subcategory:", error))
                .finally(() => {
                    setSubcategoryIdToDelete(null);
                    toggleDeleteModal();
                });
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: "Sub Category Name",
                accessor: "name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "No. of Products",
                accessor: "number",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Action",
                accessor: (cellProps) => {
                    const subcategoryId = cellProps.id;

                    return (
                        <React.Fragment>
                            <Link
                                to={`/route-money/merchant/subcategory/edit/${subcategoryId}`}
                                className="me-3 text-secondary"
                            >
                                <i className="mdi mdi-pencil font-size-18"></i>
                            </Link>
                            <Link to="#" className="me-3 text-danger" onClick={() => deleteSubcategory(subcategoryId)}>
                                <i className="mdi mdi-delete font-size-18"></i>
                            </Link>
                        </React.Fragment>
                    );
                },
                disableFilters: true,
                filterable: false,
            },
        ],
        [subcategories]
    );

    const breadcrumbItems = [
        { title: "Products", link: "/" },
        { title: "Product Sub Categories", link: "#" },
    ];

    // useEffect(() => {
    //     const apiUrlSubCategories = `${BACKEND_DOMAIN}/business/merchant/subcategories/list`;
    //     const apiUrlProducts = `${BACKEND_DOMAIN}/business/merchant/products/list`;

    //     fetch(apiUrlSubCategories, {
    //         headers: {
    //             Authorization: `Bearer ${authToken}`,
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then((subcategoryData) => {
    //             fetch(apiUrlProducts, {
    //                 headers: {
    //                     Authorization: `Bearer ${authToken}`,
    //                 },
    //             })
    //                 .then((response) => response.json())
    //                 .then((productData) => {
    //                     const productCountBySubCategory = {};

    //                     productData.results.forEach((product) => {
    //                         const subcategoryId = product.subcategory_name || "Uncategorized";
    //                         productCountBySubCategory[subcategoryId] =
    //                             (productCountBySubCategory[subcategoryId] || 0) + 1;
    //                     });

    //                     const subcategoriesWithProductCount = subcategoryData.results.map((subcategory) => ({
    //                         ...subcategory,
    //                         number: productCountBySubCategory[subcategory.name] || 0,
    //                     }));

    //                     if (subcategoriesWithProductCount.length === 0) {
    //                         setIsSubCategoriesListEmpty(true);
    //                     }

    //                     setSubCategories(subcategoriesWithProductCount);
    //                 })
    //                 .catch((error) => {
    //                     console.error("Error fetching products data:", error);
    //                 });
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching subcategories data:", error);
    //         });
    // }, [authToken]);

    useEffect(() => {
        const fetchData = async () => {
            const apiUrlSubCategories = `${BACKEND_DOMAIN}/business/merchant/subcategories/list`;
            const apiUrlProducts = `${BACKEND_DOMAIN}/business/merchant/products/list`;

            try {
                setLoading(true); // Set loading to true at the start

                // Fetch subcategories
                const subCategoryResponse = await fetch(apiUrlSubCategories, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                if (!subCategoryResponse.ok) throw new Error(`HTTP error! Status: ${subCategoryResponse.status}`);
                const subcategoryData = await subCategoryResponse.json();

                // Fetch products
                const productResponse = await fetch(apiUrlProducts, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                if (!productResponse.ok) throw new Error(`HTTP error! Status: ${productResponse.status}`);
                const productData = await productResponse.json();

                // Create a mapping of subcategory IDs to product counts
                const productCountBySubCategory = {};

                productData.results.forEach((product) => {
                    const subcategoryId = product.subcategory_name || "Uncategorized";
                    productCountBySubCategory[subcategoryId] = (productCountBySubCategory[subcategoryId] || 0) + 1;
                });

                // Map subcategories to include product counts
                const subcategoriesWithProductCount = subcategoryData.results.map((subcategory) => ({
                    ...subcategory,
                    number: productCountBySubCategory[subcategory.name] || 0,
                }));

                if (subcategoriesWithProductCount.length === 0) {
                    setIsSubCategoriesListEmpty(true);
                } else {
                    setSubCategories(subcategoriesWithProductCount);
                }

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); // Set loading to false after fetching is complete
            }
        };

        fetchData();
    }, [authToken]);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Product Sub Categories" breadcrumbItems={breadcrumbItems} />
                    <Link to="/route-money/merchant/subcategory/add" className="btn btn-primary mb-3">
                        Add Sub Category
                    </Link>
                    {deletionSuccessMessage && <Alert color="success">{deletionSuccessMessage}</Alert>}
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={subcategories || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isGlobalFilter={true}
                                isBordered={false}
                                customPageSize={10}
                                isEmpty={isSubCategoriesListEmpty}
                                isEmptyContent="There are no sub-categories available currently."
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>

            <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
                <ModalBody>Are you sure you want to delete this subcategory?</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleDeleteModal}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={confirmDelete}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={loading} centered>
                <ModalBody className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                    <div>Loading...</div>
                </ModalBody>
                </Modal>
        </React.Fragment>
    );
};

export default MerchantSubCategories;
