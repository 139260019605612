import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../../components/Common/TableContainer";
import ImageCell from "./ImageCell";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Button, Card, CardBody, Container,Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BACKEND_DOMAIN from "../../../../service";

const MerchantProducts = () => {
    const [products, setProducts] = useState([]);
    const [nextUrl, setNextUrl] = useState(null);
    const [loader, setLoaders] = useState(false);
    const [categories, setCategories] = useState([]);
    const [modal, setModal] = useState(false);
    const [selectedProductDetails, setSelectedProductDetails] = useState(null);
    const [productIdToDelete, setProductIdToDelete] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [deletionSuccessMessage, setDeletionSuccessMessage] = useState("");
    const [isProductListEmpty, setIsProductListEmpty] = useState(false);
    const [loading, setLoading] = useState(true);

    const authToken = useSelector((state) => state.Login.token);

    // Retrieve token from Redux store
    const toggleModal = () => {
        setModal(!modal);
    };
    const openModal = (productId) => {
        const selectedProduct = products.find((product) => product.id === productId);
        setSelectedProductDetails(selectedProduct);
        toggleModal();
    };
    const toggleConfirmModal = () => setConfirmModal(!confirmModal);

    const openConfirmModal = (productId) => {
        setProductIdToDelete(productId);
        toggleConfirmModal();
    };

    const confirmDelete = () => {
        if (productIdToDelete) {
            deleteProduct(productIdToDelete);
            setProductIdToDelete(null);
            toggleConfirmModal();
        }
    };

    const deleteProduct = (productId) => {
        const apiUrlDelete = `${BACKEND_DOMAIN}/business/merchant/product/${productId}/delete/`;

        fetch(apiUrlDelete, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                if (response.ok) {
                    // Update the products list after successful deletion
                    const updatedProducts = products.filter((product) => product.id !== productId);
                    setProducts(updatedProducts);
                    setDeletionSuccessMessage("Product has been deleted successfully!");
                    setTimeout(() => {
                        setDeletionSuccessMessage("");
                    }, 3000);
                } else {
                    console.error("Error deleting product:", response.status);
                }
            })
            .catch((error) => console.error("Error deleting product:", error));
    };

    useEffect(() => {
    const apiUrlProducts = `${BACKEND_DOMAIN}/business/merchant/products/list?limit=50&offset=`;
    const apiUrlCategory = `${BACKEND_DOMAIN}/business/merchant/categories`;

    const fetchProducts = async (offset = 0, allProducts = [],isInitial = true) => {
        try {
            if (isInitial) setLoading(true);
            const response = await fetch(`${apiUrlProducts}${offset}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`, // Include the token in the headers
                    "Content-Type": "application/json",
                    // Add other headers if needed
                },
            });
            const data = await response.json();

            if (data.results.length === 0 && allProducts.length === 0) {
                setIsProductListEmpty(true);
            }
          
            console.log("product count: ",data.count);
            const updatedProducts = [...allProducts, ...data.results];
            setProducts(updatedProducts);

            if (data.next) {
                console.log('next ',data.next);
                // Extract the offset from the next URL if present
                const nextOffset = new URL(data.next).searchParams.get("offset");
                fetchProducts(nextOffset, updatedProducts);
            }
        } catch (error) {
            console.error("Error fetching products:", error);
        }
        finally {
            // Set loading state only for initial fetch
            if (isInitial) setLoading(false);
        }
    };

    const fetchCategories = async (isInitial = true) => {
        try {
            if (isInitial) setLoading(true);
            const response = await fetch(apiUrlCategory, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${authToken}`, // Include the token in the headers
                    "Content-Type": "application/json",
                    // Add other headers if needed
                },
            });
            const data = await response.json();
            setCategories(data.categories);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
        finally {
            // Set loading state only for initial fetch
            if (isInitial) setLoading(false);
        }
    };

    fetchProducts(true);
    fetchCategories(true);


    const intervalId = setInterval(() => {
        fetchProducts(false); // Subsequent fetches without loading indicator
        fetchCategories(false);
    }, 30000); // Poll every 10 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
}, [authToken]);


    const categoryMapping = categories.reduce((acc, category) => {
        acc[category.id] = category.name;
        return acc;
    }, {});

    const columns = useMemo(
        () => [
            {
                Header: "Product Name",
                accessor: "name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Image",
                accessor: "image",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }) => <ImageCell value={value} />,
            },
            {
                Header: "Price (KES)",
                accessor: "price",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Offer Price (KES)",
                accessor: "offer_price",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Category",
                accessor: "category_name", // Use the correct key
                disableFilters: true,
                filterable: false,
                Cell: ({ value }) => categoryMapping[value] || value,
            },
            {
                Header: "Action",
                accessor: (cellProps) => {
                    const productId = cellProps.id;
                    // console.log(cellProps.id);
                    return (
                        <React.Fragment>
                            <Link to="#" className="me-3 text-primary" onClick={() => openModal(productId)}>
                                <i className="mdi mdi-eye font-size-18"></i>
                            </Link>
                            <Link
                                to={`/route-money/merchant/product/edit/${productId}`}
                                className="me-3 text-secondary"
                            >
                                <i className="mdi mdi-pencil font-size-18"></i>
                            </Link>

                            <Link to="#" className="me-3 text-danger" onClick={() => openConfirmModal(productId)}>
                                <i className="mdi mdi-delete font-size-18"></i>
                            </Link>
                        </React.Fragment>
                    );
                },
                disableFilters: true,
                filterable: false,
            },
        ],
        [categoryMapping]
    );

    const breadcrumbItems = [
        { title: "Products", link: "/" },
        { title: "Products List", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Products List" breadcrumbItems={breadcrumbItems} />
                    <Link to="/route-money/merchant/product/add" className="btn btn-primary mb-3">
                        Add Product
                    </Link>

                    {deletionSuccessMessage && <Alert color="success">{deletionSuccessMessage}</Alert>}

                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={products || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isGlobalFilter={true}
                                isBordered={false}                                                                                                      customPageSize={10}
                                isEmpty={isProductListEmpty}
                                isEmptyContent="You haven't added any products yet."
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal
                isOpen={modal}
                role="document"
                autoFocus={true}
                centered={true}
                className="composemodal"
                tabIndex="-1"
                toggle={toggleModal}
                size="lg"
            >
                <div className="modal-content">
                    <ModalHeader>Product Detail</ModalHeader>
                    <ModalBody>
                        {selectedProductDetails && (
                            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Image</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Subcategory</th>
                                            <th>Sides</th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <img
                                                    src={selectedProductDetails.image}
                                                    alt={selectedProductDetails.name}
                                                    style={{
                                                        width: "70px",
                                                        height: "50px",
                                                        objectFit: "cover",
                                                        borderRadius: "5px",
                                                    }}
                                                />
                                            </td>
                                            <td>{selectedProductDetails.name}</td>
                                            <td>{selectedProductDetails.description}</td>
                                            <td>{selectedProductDetails.subcategory_name}</td>
                                            <td>{selectedProductDetails.productOptions || "None"}</td>
                                            <td>KES {selectedProductDetails.price}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </ModalBody>
                </div>
            </Modal>
            <Modal isOpen={confirmModal} toggle={toggleConfirmModal}>
                <ModalHeader toggle={toggleConfirmModal}>Confirm Delete</ModalHeader>
                <ModalBody>Are you sure you want to delete this product?</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleConfirmModal}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={confirmDelete}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={loading} centered>
                <ModalBody className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                    <div>Loading...</div>
                </ModalBody>
                </Modal>
        </React.Fragment>
    );
};

export default MerchantProducts;
