import React, {useState, useEffect} from "react";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Container, Row } from "reactstrap";
import MiniWidgets from "../../Dashboard/MiniWidgets";
import { useSelector } from 'react-redux';
import axios from "axios";

const RevenueReport = () => {
    const token = useSelector((state) => state.Login.token);
    const [reportData, setReportData] = useState(null);
    const [localIsBusinessPartner, setLocalIsBusinessPartner] = useState(null);

    // end enter pincode
    useEffect(() => {
      const fetchIsPartner = async () => {
          try {
              const localData = localStorage.getItem("authUser");
              if (localData) {
                  const info = JSON.parse(localData);
                  setLocalIsBusinessPartner(info.is_business_partner);
              }
          } catch (e) {
              console.error(e);
          }
      };
  
      fetchIsPartner();
  }, []);

  useEffect(() => {
    if (token) {
      const fetchReports = async () => {
        try {

          let url;
          if(localIsBusinessPartner){
            url = 'https://backoffice.route.money/api/v1/business/report/data/';
          }else{
            url = 'https://backoffice.route.money/api/v1/business/admin/report/data';
          }
          const response = await axios.get(url,{
            
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          setReportData(response.data);
          console.log('revenue card data ',response.data);
        } catch (error) {
          console.error("Error fetching reports:", error);
        }
      };

      fetchReports();
    }
  }, [token]);
  
    // Define reports for Admins:
    
    const superUserReports = [
        {
            icon: "ri-stack-line",
            title: "Transactions Volume",
            value: `KES ${parseFloat(reportData ? reportData.total_value : 0).toFixed(2)}`,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "#",
        },
        {
            icon: "ri-shopping-cart-2-line",
            title: "Total Orders",
            value: `KES ${parseFloat(reportData ? reportData.total_orders : 0).toFixed(2)}`,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "",
        },
        {
            icon: "ri-user-line",
            title: "Total Cashback Value",
            value: `KES ${reportData ? reportData.cashback : 0}`,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "",
        },

        {
            icon: "ri-coin-line",
            title: "Transacted Fees",
            value: `KES ${parseFloat(reportData ? reportData.fees : 0).toFixed(2)}`,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "",
        },
    ];

    const breadcrumbItems = [
        { title: "Reports", link: "/" },
        { title: "Revenue Reports", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Revenue Reports" breadcrumbItems={breadcrumbItems} />
                    <Row className="pb-5">
                        <MiniWidgets reports={superUserReports} />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default RevenueReport;
