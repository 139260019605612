import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import axios from "axios";
import { RevenueAnalyticsChart } from "./Charts";
import "./dashboard.scss";
import BACKEND_DOMAIN from "../../service";

const RevenueAnalytics = () => {
  const [revenuedata, setRevenueData] = useState([]);
  const token = useSelector((state) => state.Login.token);
  const [localIsBusinessPartner, setLocalIsBusinessPartner] = useState(null);

  // end enter pincode
  useEffect(() => {
    const fetchIsPartner = async () => {
        try {
            const localData = localStorage.getItem("authUser");
            if (localData) {
                const info = JSON.parse(localData);
                setLocalIsBusinessPartner(info.is_business_partner);
            }
        } catch (e) {
            console.error(e);
        }
    };

    fetchIsPartner();
}, []);


  // Define the fetchRevenueData function
  const fetchRevenueData = async () => {
    try {
      let apiUrl;
      if(localIsBusinessPartner){
        console.log("revenue data local business");
        apiUrl = `${BACKEND_DOMAIN}/wallets/merchant/dashboard/revenue/card/data`;
      }else{
        console.log("revenue data not a local business");
         apiUrl = `${BACKEND_DOMAIN}/wallets/admin/dashboard/revenue/card/data`
      }
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Set the fetched user data in the state
      setRevenueData(response.data);
    } catch (error) {
      console.error("Error fetching user details:", error.message);
    }
  };

  // Use useEffect to fetch user details from the API when the component mounts
  useEffect(() => {
    fetchRevenueData();
  }, [token]); // The dependency array ensures that the effect runs only when authToken changes

 // console.log("revenue ",revenuedata);
  return (
    <React.Fragment>
      {/* styles */}
      <style>
                {`
                    .icon-square {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                    }
                    .icon-credit {
                        background-color: #5664d2; /* Color for Credit */
                    }
                    .icon-debit {
                        background-color: #1cbb8c; /* Color for Debit */
                    }
                    .icon-credit, .icon-debit {
                        margin-right: 5px;
                    }
                `}
            </style>
            {/* end styles */}
      <Card>
        <CardBody>
          <h4 className="card-title mb-2">Revenue Analytics </h4>
          <div id="line-column-chart" className="apex-charts" dir="ltr">
            <RevenueAnalyticsChart />
 
            
          </div>
        </CardBody>

        <CardBody className="border-top text-center">
      <Row>
        <Col sm={6}>
          <div className="mt-4 mt-sm-0">
            <p className="mb-2 text-muted text-truncate">
              <span className="icon-square icon-credit me-1"></span> Credit
            </p>
          </div>
        </Col>

        <Col sm={6}>
          <div className="mt-4 mt-sm-0">
            <p className="mb-2 text-muted text-truncate">
              <span className="icon-square icon-debit me-1"></span> Debit
            </p>
          </div>
        </Col>
      </Row>
    </CardBody>

        <CardBody className="border-top text-center">
          <Row>
            <Col sm={4}>
              <div className="mt-4 mt-sm-0">
                <p className="mb-2 text-muted text-truncate">
                  <i className="mdi mdi-circle text-white font-size-10 me-1"></i> This month :
                </p>
                <div className="d-inline-flex">
                  {/* setRevenueData(parseFloat(response.data.toFixed(2))); */}
                  <h5 className="me-2">KES {parseFloat(revenuedata.this_month_revenue).toFixed(2)}</h5>
                  
                  {/* <h5 className="mb-0">KES {parseFloat(revenuedata.this_month_revenue).toFixed(2)}</h5> */}

                </div>
              </div>
            </Col>

            <Col sm={4}>
              <div className="mt-4 mt-sm-0">
                <p className="mb-2 text-muted text-truncate">
                  <i className="mdi mdi-circle text-warning font-size-10 me-1"></i> This Year :
                </p>
                <div className="d-inline-flex">
                  {/* <h5 className="mb-0 me-2">KES {parseFloat(revenuedata.this_year_revenue.toFixed(2))}</h5> */}
                  <h5 className="me-2">KES {parseFloat(revenuedata.this_year_revenue).toFixed(2)}</h5>
                  
                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="mt-4 mt-sm-0">
                <p className="mb-2 text-muted text-truncate">
                  <i className="mdi mdi-circle text-dark font-size-10 me-1"></i> Previous Year :
                </p>
                <div className="d-inline-flex">
                  <h5 className="mb-0">KES {parseFloat(revenuedata.previous_year_revenue).toFixed(2)}</h5>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RevenueAnalytics;
