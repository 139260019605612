import React, { Component } from "react";

import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";
import axios from "axios";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// adding the packages

import withRouter from "../../components/Common/withRouter";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
// actions
import { checkLogin, loginUserSuccessful, apiError, setAuthToken } from "../../store/actions";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light-new.jpg";
import { bindActionCreators } from "redux";
import BACKEND_DOMAIN from "../../service";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = { email: "", password: "", isLoading: false, loginErrorMessage: null, passwordVisible: false };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    }
    // const navigate = useNavigate();

    togglePasswordVisibility = () => {
        this.setState({ passwordVisible: !this.state.passwordVisible });
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        this.setState({ isLoading: true });

        const { email, password } = this.state;

        const apiUrl = `${BACKEND_DOMAIN}/admin/merchants/login/`;

        // Dispatch the action
        this.props.actions.checkLogin(email, password, this.props.router.navigate);

        // Handle async logic (e.g., API call) in your component
        try {
            const response = await axios.post(apiUrl, { email, password });
            const { data } = response;
            const token = data.user.token;
             
            this.props.actions.setAuthToken(token);
            this.props.actions.loginUserSuccessful(data.user);

            let loginscope=data.user.role
            let permissions=data.user.permissions
            // Store authentication status
            localStorage.setItem("authUser", JSON.stringify(data.user));
          

            // Navigate to the dashboard
            if (loginscope ===''){
                //console.log("other scope");
                this.props.router.navigate("/route-money/dashboard");
            }else if (loginscope === 'Waiter')
            {
               // console.log("waiter scope");
                this.props.router.navigate("/route-money/waiter/pending-orders");
            }
            else if (loginscope === 'Manager')
                {
                   // console.log("manager scope");
                    this.props.router.navigate("/route-money/branch/dashboard");
                }
           
        } catch (error) {
            this.props.actions.apiError(error);
            if(error.response.data.user.errors.error){
            this.setState({ loginErrorMessage: error.response.data.user.errors.error });
            }
            // Clear the error message after 3 seconds
            setTimeout(() => {
                this.setState({ loginErrorMessage: null });
                this.props.actions.apiError(""); // Clear error in Redux store as well
            }, 3500);
        }

        this.setState({ isLoading: false });
    };
    ndleS
    componentDidMount() {
        this.props.actions.apiError("");
        document.body.classList.add("auth-body-bg");

        // Check for the presence of the token in localStorage
        const storedToken = localStorage.getItem("authToken");

        if (storedToken) {
            // If token is present, dispatch an action to set it in the Redux store
            this.props.actions.setAuthToken(storedToken);
        }
    }

    componentWillUnmount() {
        document.body.classList.remove("auth-body-bg");
    }

    render() {
        const { loginError } = this.props;
        const { isLoading, loginErrorMessage } = this.state;

        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            <Link to="/" className="">
                                                                <img
                                                                    src={logodark}
                                                                    alt=""
                                                                    height="35"
                                                                    className="auth-logo logo-light mx-auto"
                                                                />

                                                                <img
                                                                    src={logolight}
                                                                    alt=""
                                                                    height="35"
                                                                    className="auth-logo logo-dark mx-auto"
                                                                />
                                                            </Link>
                                                        </div>

                                                        <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                                                        <p className="text-muted">
                                                            Sign in to continue to Route Money.
                                                        </p>
                                                    </div>

                                                    <div className="p-2 mt-3">
                                                    {loginError && loginErrorMessage && (
                                                                <Alert color="danger">{loginErrorMessage}</Alert>
                                                            )}
                                                     </div>

                                                    <div className="p-2 mt-3">
                                                        <AvForm
                                                            className="form-horizontal"
                                                            onValidSubmit={this.handleSubmit}
                                                        >
                                                            <Label htmlFor="email">Email</Label>
                                                            <div className="mb-3">
                                                                <AvField
                                                                    name="email"
                                                                    value={this.state.email}
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="email"
                                                                    validate={{ email: true, required: true }}
                                                                    placeholder="Enter email"
                                                                    onChange={(e) =>
                                                                        this.setState({ email: e.target.value })
                                                                    }
                                                                />
                                                            </div>
                                                            <Label htmlFor="userpassword">Password</Label>
                                                            <div className="mb-1">
                                                                <AvField
                                                                    name="password"
                                                                    value={this.state.password}
                                                                    type={
                                                                        this.state.passwordVisible ? "text" : "password"
                                                                    }
                                                                    className="form-control"
                                                                    id="userpassword"
                                                                    placeholder="Enter password"
                                                                    onChange={(e) =>
                                                                        this.setState({ password: e.target.value })
                                                                    }
                                                                    validate={{ required: true }}
                                                                />
                                                                <div className="d-flex justify-content-end mt-1">
                                                                    <small
                                                                        style={{
                                                                            marginTop: !this.state.passwordMatch
                                                                                ? "-10px"
                                                                                : 0,
                                                                        }}
                                                                        className="text-muted cursor-pointer"
                                                                        onClick={this.togglePasswordVisibility}
                                                                    >
                                                                        {this.state.passwordVisible ? "Hide" : "Show"}
                                                                    </small>
                                                                </div>
                                                            </div>

                                                            <div className="form-check">
                                                                <Input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id="customControlInline"
                                                                />
                                                                <Label
                                                                    className="form-check-label"
                                                                    htmlFor="customControlInline"
                                                                >
                                                                    Remember me
                                                                </Label>
                                                            </div>


                                                            <div className="mt-4 text-center">
                                                                <Button
                                                                    color="primary"
                                                                    className="w-md waves-effect waves-light"
                                                                    type="submit"
                                                                    disabled={isLoading}
                                                                >
                                                                    {isLoading ? (
                                                                        <div
                                                                            class="spinner-border spinner-border-sm"
                                                                            role="status"
                                                                        >
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div>
                                                                    ) : (
                                                                        "Login"
                                                                    )}
                                                                </Button>
                                                            </div>

                                                            <div className="mt-2 text-center">
                                                                <Link
                                                                    to="/route-money/merchant/forgot-password"
                                                                    className="text-muted"
                                                                >
                                                                    <i className="mdi mdi-lock me-1"></i> Forgot your
                                                                    password?
                                                                </Link>
                                                            </div>
                                                        </AvForm>
                                                    </div>

                                                    <div
                                                        className={`${
                                                            loginErrorMessage ? "mt-1 text-center" : "mt-5 text-center"
                                                        }`}
                                                    >
                                                        <p>
                                                            Don't have an account ?{" "}
                                                            <Link
                                                                to="/route-money/merchant/register"
                                                                className="fw-medium text-primary"
                                                            >
                                                                {" "}
                                                                Register{" "}
                                                            </Link>{" "}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="authentication-bg"></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = (state) => {
    const { loginError } = state.Login;
    return { loginError };
};
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ checkLogin, setAuthToken, loginUserSuccessful, apiError }, dispatch),
});

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Login));
