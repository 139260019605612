import React, { useMemo, useEffect, useState } from "react";
import TableContainer from "../../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Card, CardBody, Container,Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BACKEND_DOMAIN from "../../../../service";

const MerchantCategories = () => {
    const [categories, setCategories] = useState([]);
    const authToken = useSelector((state) => state.Login.token);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deletionSuccessMessage, setDeletionSuccessMessage] = useState("");
    const [loading, setLoading] = useState(true)
    const [categoryIdToDelete, setCategoryIdToDelete] = useState(null);

    const [isCategoryListEmpty, setIsCategoryListEmpty] = useState(false);

    const toggleDeleteModal = () => setDeleteModal(!deleteModal);

    const deleteCategory = (categoryId) => {
        setCategoryIdToDelete(categoryId);
        toggleDeleteModal();
    };

    const confirmDelete = () => {
        if (categoryIdToDelete) {
            const apiUrlDelete = `${BACKEND_DOMAIN}/business/merchant/category/${categoryIdToDelete}/delete/`;

            fetch(apiUrlDelete, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.ok) {
                        const updatedCategories = categories.filter((category) => category.id !== categoryIdToDelete);
                        setCategories(updatedCategories);
                        setDeletionSuccessMessage("Category has been deleted successfully!");
                        setTimeout(() => {
                            setDeletionSuccessMessage("");
                        }, 3000);
                    } else {
                        console.error("Error deleting category:", response.status);
                    }
                })
                .catch((error) => console.error("Error deleting category:", error))
                .finally(() => {
                    setCategoryIdToDelete(null);
                    toggleDeleteModal();
                });
        }
    };

    const columns = useMemo(
        () => [
            {
                Header: "Category Name",
                accessor: "name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "No. of Products",
                accessor: "number",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Action",
                accessor: (cellProps) => {
                    const categoryId = cellProps.id;

                    return (
                        <React.Fragment>
                            <Link
                                to={`/route-money/merchant/category/edit/${categoryId}`}
                                className="me-3 text-secondary"
                            >
                                <i className="mdi mdi-pencil font-size-18"></i>
                            </Link>
                            <Link to="#" className="me-3 text-danger" onClick={() => deleteCategory(categoryId)}>
                                <i className="mdi mdi-delete font-size-18"></i>
                            </Link>
                        </React.Fragment>
                    );
                },
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );

    const breadcrumbItems = [
        { title: "Products", link: "/" },
        { title: "Product Categories", link: "#" },
    ];

    // useEffect(() => {
    //     const apiUrlCategories = `${BACKEND_DOMAIN}/business/merchant/categories`;
    //     const apiUrlProducts = `${BACKEND_DOMAIN}/business/merchant/products/list`;

    //     // Fetch categories from the API
    //     fetch(apiUrlCategories, {
    //         headers: {
    //             Authorization: `Bearer ${authToken}`, // Replace with the actual merchant token
    //         },
    //     })
    //         .then((response) => response.json())
    //         .then((categoryData) => {
    //             fetch(apiUrlProducts, {
    //                 headers: {
    //                     Authorization: `Bearer ${authToken}`, // Replace with the actual merchant token
    //                 },
    //             })
    //                 .then((response) => response.json())
    //                 .then((productData) => {
    //                     // Create a mapping of category IDs to product counts
    //                     const productCountByCategory = {};

    //                     // Count the number of products for each category
    //                     productData.results.forEach((product) => {
    //                         const categoryId = product.category_name || "Uncategorized";
    //                         productCountByCategory[categoryId] = (productCountByCategory[categoryId] || 0) + 1;
    //                     });

    //                     // Update state with the fetched data
    //                     const categoriesWithProductCount = categoryData.categories.map((category) => ({
    //                         ...category,
    //                         number: productCountByCategory[category.name] || 0,
    //                     }));

    //                     if (categoriesWithProductCount.length === 0) {
    //                         setIsCategoryListEmpty(true);
    //                     }
    //                     setCategories(categoriesWithProductCount);
    //                 })
    //                 .catch((error) => {
    //                     console.error("Error fetching products data:", error);
    //                 });
    //         })
    //         .catch((error) => {
    //             console.error("Error fetching categories data:", error);
    //         });
    // }, []);

    useEffect(() => {
        const fetchCategoriesAndProducts = async () => {
            const apiUrlCategories = `${BACKEND_DOMAIN}/business/merchant/categories`;
            const apiUrlProducts = `${BACKEND_DOMAIN}/business/merchant/products/list`;

            try {
                setLoading(true); // Set loading to true at the start

                // Fetch categories
                const categoryResponse = await fetch(apiUrlCategories, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                const categoryData = await categoryResponse.json();

                // Fetch products
                const productResponse = await fetch(apiUrlProducts, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                const productData = await productResponse.json();

                // Create a mapping of category IDs to product counts
                const productCountByCategory = {};

                // Count the number of products for each category
                productData.results.forEach((product) => {
                    const categoryId = product.category_name || "Uncategorized";
                    productCountByCategory[categoryId] = (productCountByCategory[categoryId] || 0) + 1;
                });

                // Update state with the fetched data
                const categoriesWithProductCount = categoryData.categories.map((category) => ({
                    ...category,
                    number: productCountByCategory[category.name] || 0,
                }));

                if (categoriesWithProductCount.length === 0) {
                    setIsCategoryListEmpty(true);
                }
                setCategories(categoriesWithProductCount);

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); // Set loading to false after fetching is complete
            }
        };

        fetchCategoriesAndProducts();
    }, [authToken]);
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Product Categories" breadcrumbItems={breadcrumbItems} />
                    <Link to="/route-money/merchant/category/add" className="btn btn-primary mb-3">
                        Add Category
                    </Link>
                    {deletionSuccessMessage && <Alert color="success">{deletionSuccessMessage}</Alert>}
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={categories || []}
                                isPagination={false}
                                isGlobalFilter={true}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                                isEmpty={isCategoryListEmpty}
                                isEmptyContent="There are no categories available currently."
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal isOpen={deleteModal} toggle={toggleDeleteModal}>
                <ModalHeader toggle={toggleDeleteModal}>Confirm Delete</ModalHeader>
                <ModalBody>Are you sure you want to delete this category?</ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggleDeleteModal}>
                        Cancel
                    </Button>
                    <Button color="danger" onClick={confirmDelete}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>


            <Modal isOpen={loading} centered>
                <ModalBody className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                    <div>Loading...</div>
                </ModalBody>
                </Modal>
        </React.Fragment>
    );
};

export default MerchantCategories;
