import React, {useState,useEffect } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import "./dashboard.scss";
import BACKEND_DOMAIN from "../../../service";
import { useSelector } from "react-redux";
import axios from "axios";

const EarningReports = () => {
    const [earningdata, setEarningData] = useState([]);
  const token = useSelector((state) => state.Login.token);

  // Define the fetchRevenueData function
  const fetchRevenueData = async () => {
    try {
      const apiUrl = `${BACKEND_DOMAIN}/wallets/merchant/dashboard/revenue/analysis`;
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Set the fetched user data in the state
      setEarningData(response.data);
    } catch (error) {
      console.error("Error fetching user details:", error.message);
    }
  };

    // Use useEffect to fetch user details from the API when the component mounts
    useEffect(() => {
        fetchRevenueData();
      }, [token]); // The dependency array ensures that the effect runs only when authToken changes
    
      //console.log("Earning ",earningdata);
      
  const [menu, setMenu] = useState(false);
  const [series, setSeries] = useState([0]);
  const [options, setOptions] = useState({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    colors: ["#5664d2"],
    stroke: {
      lineCap: "round",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: false,
        },
      },
    },
  });
  
  const [series2, setSeries2] = useState([0]);
  const [options2, setOptions2] = useState({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    colors: ["#1cbb8c"],
    stroke: {
      lineCap: "round",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: false,
        },
      },
    },
  });

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <h4 className="card-title mb-4">Earning Reports</h4>
          <div className="text-center">
            <Row>
              <Col sm={6}>
                <div>
                  <div className="mb-3">
                    <div id="radialchart-1" className="apex-charts">
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="radialBar"
                        height="60"
                      />
                    </div>
                  </div>
                  <p className="text-muted text-truncate mb-2">Weekly Earnings</p>
                  <h5 className="mb-0">KES {earningdata.weekly_earnings}</h5>
                </div>
              </Col>
              <Col sm={6}>
                <div className="mt-5 mt-sm-0">
                  <div className="mb-3">
                    <div id="radialchart-2" className="apex-charts">
                      <ReactApexChart
                        options={options2}
                        series={series2}
                        type="radialBar"
                        height="60"
                      />
                    </div>
                  </div>
                  <p className="text-muted text-truncate mb-2">Monthly Earnings</p>
                  <h5 className="mb-0">KES {earningdata.monthly_earnings}</h5>
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default EarningReports;
