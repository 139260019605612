import React, {useEffect,useState, useMemo } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import axios from "axios";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import dateFormatter from "../../Orders/utilities/dateFormatter";
import { Card, CardBody, Container, Alert, Modal,ModalBody,Spinner } from "reactstrap";
import { useSelector } from "react-redux";

const CollectionTransactions = () => {
    const authToken = useSelector((state) => state.Login.token);
    // fetch collections data
const [collections, setCollections] = useState([]);
const [networkmessage, setNetworkMessage] = useState('');
const [errorMessage, setErrorMessage] = useState('');
const [loading, setLoading] = useState(true);

//console.log(" auth token ", authToken);

const fetchCollections = async () => {
    const collectionApi = `https://backoffice.route.money/api/v1/wallets/admin/transactions/collections`;

    try {
        setLoading(true);
        const response = await axios.get(collectionApi, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        console.log('collections data', response.data.results);
        const collectionData = response.data.results;
        setCollections(collectionData)

    } catch (e) {
        if (e.response && e.response.status === 500) {
            console.error("Server error (500): ", e.message);
            setNetworkMessage("Your network connection is slow.");
            setTimeout(() => {
                setNetworkMessage("");
            }, 3000);
        } else {
            console.error("Failed to fetch collections -> ", e.message);
            setErrorMessage("Failed to fetch collections");

            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        }
    }
    finally {
        setLoading(false); // Set loading to false after fetching is complete
    }
};

useEffect(() => {
    fetchCollections();
}, []);


    const columns = useMemo(
        () => [
            {
                Header: "Transaction Reference",
                accessor: "reference",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Sender's Account",
                accessor: "wallet_account",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Amount",
                accessor: "amount",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Transaction Fee",
                accessor: "fee",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Recipient's Account",
                accessor: "beneficiary_account",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date",
                accessor: "created_at",
                Cell: ({ cell: { value } }) => {
                    const datePart = dateFormatter(value);
                    return datePart;
                },
                disableFilters: true,
                filterable: false,
            }
        ],
        []
    );

    const breadcrumbItems = [
        { title: "Collections", link: "/" },
        { title: "Collection Transactions", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Collection Transactions" breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                        {networkmessage && <Alert color="warning">{networkmessage}</Alert>}
                            <TableContainer
                                columns={columns || []}
                                data={collections || []}
                                isPagination={false}
                                isGlobalFilter={true}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>

            <Modal isOpen={loading} centered>
                <ModalBody className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                    <div>Loading...</div>
                </ModalBody>
                </Modal>
        </React.Fragment>
    );
};

export default CollectionTransactions;
