import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../components/Common/TableContainer";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Col, Card, CardBody, Container, Modal, ModalHeader, ModalBody, ModalFooter,Spinner, Button, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import BACKEND_DOMAIN from "../../service";
import dateFormatter from "../Orders/utilities/dateFormatter";
import { ORDER_TYPE_COLORS } from "../Orders/utilities/orderTypes";
import {
    fetchProductsOrdersAndBranches,
    fetchProductOffersData,
    fetchCashbackOffersData,
    fetchWalletBalanceData
} from '../Dashboard/data_fetching/dashboardData';
import axios from "axios";

const Withdraw = () => {
    const token = useSelector((state) => state.Login.token);

    const [orders, setOrders] = useState([]);
    const [menuOpen, setMenuOpen] = useState({});
    const [modal, setModal] = useState(false);
    const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);
    const [walletBalance, setWalletBalance] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [pendingOrders, setPendingOrders] = useState(0);
    const [rejectedOrders, setRejectedOrders] = useState(0);
    const [approvedOrders, setApprovedOrders] = useState(0);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [orderListIsEmpty, setOrderListIsEmpty] = useState(false);
    const [loading, setLoading] = useState(true);

    // Order Badge color for the modal:
    const [orderBadgeColor, setOrderBadgeColor] = useState("success");


    // f3erching global data
    const setLedger = async (token) => {
    try {
        const [
            walletBalanceData
        ] = await Promise.all([
            fetchWalletBalanceData(token)
        ]);
        setWalletBalance(walletBalanceData.ledger_balance); // Ensure correct access to ledger_balance
    }catch(e){
  console.log("failed to fetch data ",e);
    }
};

useEffect(() => {
    setLedger(token);
}, [token]);

    const [transactions, setTransactions] = useState([]);
    const [isTransactionsListEmpty, setIsTransactionsListEmpty] = useState(false);

    const fetchTransactions = async ({token, isInitial = false}) => {
        const apiURL = `${BACKEND_DOMAIN}/wallets/merchant/transactions`;

        try {
            if (isInitial) setLoading(true); 
            const response = await axios.get(apiURL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const transactionsList = response.data.results;
            if (transactionsList.length === 0) {
                setIsTransactionsListEmpty(true);
            }

             // Format the updated_at field for each transaction
        const formattedTransactions = transactionsList.map(transaction => {
            const dateObj = new Date(transaction.created_at);
            const formattedDate = `${dateObj.toLocaleDateString('en-GB').replace(/\//g, '-')} ${dateObj.toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit'
            })}`;
            return { ...transaction, formatted_created_at: formattedDate };
        });

            setTransactions(formattedTransactions);
        } catch (e) {
            console.error("Error fetching Transactions -> ", e);
        }
        finally {
            if (isInitial) setLoading(false); // Set loading to false once data is loaded
        }
    };

    useEffect(() => {
        fetchTransactions({token:token, isInitial:true});

        // Set up polling every 10 seconds for subsequent data fetches without loading spinner
        const intervalId = setInterval(() => {
           fetchTransactions({token:token, isInitial:true});// No `true` argument, so `isInitial` is `false`
       }, 30000); // Poll every 10 seconds

       // Cleanup interval on component unmount
       return () => clearInterval(intervalId);
        
    }, [token]);

    const columns = useMemo(
        () => [
            {
                Header: "Transaction Code",
                accessor: "reference",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Sender",
                accessor: "sender_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Amount",
                accessor: "amount",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Transaction Fee",
                accessor: "fee",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Wallet Account",
                accessor: "wallet_account",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date",
                accessor: "formatted_created_at",
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );
    const breadcrumbItems = [
        { title: "Withdraw", link: "#" },
        { title: "All withdraws", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Withdraw" breadcrumbItems={breadcrumbItems} />
                    <Link to="/route-money/withdraw-checkout" className="btn btn-warning mb-3">
                        Withdraw Cash
                    </Link>
                    <Row>
                        <Col md={3}>
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="flex-1 overflow-hidden">
                                            <h4 className="text-truncate font-size-24 font-bold mb-2">Kes. {walletBalance}</h4>
                                            {/* <h4 className="mb-0">{totalOrders}</h4> */}
                                        </div>
                                        <div className="text-primary">
                                            {/* <i className="mdi mdi-order-bool-descending font-size-24"></i> */}
                                            <i className="mdi mdi-wallet font-size-24"></i>
                                        </div>
                                    </div>
                                </CardBody>

                                <CardBody className="border-top py-3">
                                    <div className="text-truncate">
                                        <span className="badge bg-success-subtle text-success font-size-11 me-1"></span>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                       
                    </Row>

                    <Breadcrumbs title="Transactions" breadcrumbItems={breadcrumbItems} />
                    <Card>
                 
                        <CardBody>
                        <TableContainer
                                columns={columns || []}
                                data={transactions || []}
                                isPagination={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                isGlobalFilter={true}
                                customPageSize={10}
                                isEmpty={isTransactionsListEmpty}
                                isEmptyContent="There are currently no transactions available"
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal isOpen={loading} centered>
                <ModalBody className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                    <div>Loading...</div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Withdraw;
