import React, {useEffect, useState, useMemo } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import dateFormatter from "../../Orders/utilities/dateFormatter";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Container,Alert } from "reactstrap";
import { useSelector } from "react-redux";
import axios from "axios";
const PaymentMethodCell = () => {
    return <div>route-route</div>;
};

const IntrawalletTransactions = () => {

const authToken = useSelector((state) => state.Login.token);
    // fetch collections data
const [intrawalletTransactions, setintrawalletTransactions] = useState([]);
const [networkmessage, setNetworkMessage] = useState('');
const [errorMessage, setErrorMessage] = useState('');

const fetchCollections = async () => {
    const collectionApi = `https://backoffice.route.money/api/v1/wallets/admin/transactions`;

    try {
        const response = await axios.get(collectionApi, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

       // console.log('collections data', response.data.results);
        const intrawalletData = response.data.results;
       

    } catch (e) {
        if (e.response && e.response.status === 500) {
            console.error("Server error (500): ", e.message);
            setNetworkMessage("Your network connection is slow.");
            setTimeout(() => {
                setNetworkMessage("");
            }, 3000);
        } else {
            console.error("Failed to fetch tranactions -> ", e.message);
            setErrorMessage("Failed to fetch tranactions ");

            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        }
    }
};

useEffect(() => {
    fetchCollections();
}, []);


    const columns = useMemo(
        () => [
            {
                Header: "Sender's Account",
                accessor: "sender_account",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Amount",
                accessor: "transaction_amount",
                disableFilters: true,
                filterable: false,
            },

            {
                Header: "Payment Method",
                accessor: "payment_method",
                Cell: PaymentMethodCell,
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Recipient's Account",
                accessor: "recipient_account",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date",
                accessor: "date",
                Cell: ({ cell: { value } }) => {
                    const datePart = dateFormatter(value);
                    return datePart;
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Status",
                accessor: "transaction_statement",
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );

    const breadcrumbItems = [
        { title: "Merchant Payments", link: "/" },
        { title: "Intrawallet payments", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Intrawallet Transactions" breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                        {networkmessage && <Alert color="warning">{networkmessage}</Alert>}
                            <TableContainer
                                columns={columns || []}
                                data={intrawalletTransactions || []}
                                isPagination={false}
                                // isGlobalFilter={false}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default IntrawalletTransactions;
