import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import MiniWidgets from "./MiniWidgets";
import RevenueAnalytics from "./RevenueAnalytics";
import SalesAnalytics from "./SalesAnalytics";
import EarningReports from "./EarningReports";
import LatestTransactions from "./LatestTransactions";
import RecentOrders from "./RecentOrders";
import {
    fetchProductsOrdersAndBranches,
    fetchProductOffersData,
    fetchCashbackOffersData,
    fetchWalletBalanceData
} from "./data_fetching/dashboardData";

const Dashboard = ({ isBusinessPartner }) => {
    const authToken = useSelector((state) => state.Login.token);

    const [localIsBusinessPartner, setLocalIsBusinessPartner] = useState(isBusinessPartner);

    const [productsCount, setProductsCount] = useState(0);
    const [branchesCount, setBranchesCount] = useState(0);
    const [ordersCount, setOrdersCount] = useState(0);
    const [productOffers, setProductOffers] = useState(0);
    const [cashbackOffers, setCashbackOffers] = useState(0);
    const [walletBalance, setWalletBalance] = useState(0);

    const breadcrumbItems = [
        { title: "Route Money", link: "/" },
        { title: "Dashboard", link: "#" },
    ];

    useEffect(() => {
        const fetchIsPartner = async () => {
            try {
                const localData = localStorage.getItem("authUser");
                if (localData) {
                    const info = JSON.parse(localData);
                    setLocalIsBusinessPartner(info.is_business_partner);
                }
            } catch (e) {
                console.error(e);
            }
        };

        fetchIsPartner();
    }, []);

    const setDashboardInfo = async (token) => {
        try {
            const [
                dashboardData,
                productOffersCount,
                cashbackOffersCount,
                walletBalanceData
            ] = await Promise.all([
                fetchProductsOrdersAndBranches(token),
                fetchProductOffersData(token),
                fetchCashbackOffersData(token),
                fetchWalletBalanceData(token)
            ]);

            setProductsCount(dashboardData.cards_data.products_count);
            setBranchesCount(dashboardData.cards_data.branches_count);
            setOrdersCount(dashboardData.cards_data.orders_count);

            setProductOffers(productOffersCount);
            setCashbackOffers(cashbackOffersCount);
            setWalletBalance(walletBalanceData.ledger_balance); // Ensure correct access to ledger_balance
        } catch (e) {
            console.error("Failed to fetch dashboard data ->", e);
        }
    };

    useEffect(() => {
        setDashboardInfo(authToken);
    }, [authToken]);

    const businessPartnerReports = [
        {
            icon: "ri-currency-line",
            title: "Wallet Balance",
            value: `KES ${walletBalance}`,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "#",
        },
        {
            icon: "ri-shopping-bag-2-line",
            title: "Number of Products",
            value: productsCount,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/products",
        },
        {
            icon: "ri-shopping-cart-2-line",
            title: "Total Orders",
            value: ordersCount,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/order-history",
        },
        {
            icon: "ri-store-line",
            title: "Your Branches",
            value: branchesCount,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/branch/list",
        },
        {
            icon: "ri-coupon-line",
            title: "Product Offers",
            value: productOffers,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/product-offers",
        },
        {
            icon: "ri-refund-2-line",
            title: "Cashback Offers",
            value: cashbackOffers,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchant/cashback-offers",
        },
    ];

    const superUserReports = [
        {
            icon: "ri-currency-line",
            title: "Master Wallet Bal",
            value: `KES ${walletBalance}`,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/revenue-report",
        },
        {
            icon: "ri-stack-line",
            title: "Gross Merchandise Value (GMV)",
            value: "KES 0",
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/collection-transactions",
        },
        {
            icon: "ri-wallet-2-line",
            title: "Monthly Revenue",
            value: "KES 0",
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/revenue-report",
        },
        {
            icon: "ri-group-line",
            title: "Number of Users",
            value: 1,
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/customers",
        },
        {
            icon: "ri-medium-line",
            title: "Average Revenue Per User (ARPU)",
            value: "KES 0",
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/merchants",
        },
        {
            icon: "ri-group-2-line",
            title: "Customer Churn Rate",
            value: "0.0 %",
            rate: "2.4%",
            desc: "From previous month",
            routeTo: "/route-money/customers",
        },
    ];

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    {!localIsBusinessPartner && (
                        <>
                            <Breadcrumbs title="Admin Dashboard" breadcrumbItems={breadcrumbItems} />

                            <Row>
                                <Col xl={8}>
                                    <Row>
                                        <MiniWidgets reports={superUserReports} />
                                    </Row>
                                    <RevenueAnalytics />
                                </Col>
                                <Col xl={4}>
                                    <SalesAnalytics isAdmin={true} />
                                    <EarningReports />
                                </Col>
                            </Row>
                            <Row>
                                <LatestTransactions />
                                <RecentOrders isAdmin={true} />
                            </Row>
                        </>
                    )}
                    {localIsBusinessPartner && (
                        <>
                            <Breadcrumbs title="Merchant Dashboard" breadcrumbItems={breadcrumbItems} />

                            <Row>
                                <Col xl={8}>
                                    <Row>
                                        <MiniWidgets reports={businessPartnerReports} />
                                    </Row>
                                    <RevenueAnalytics />
                                </Col>
                                <Col xl={4}>
                                    <SalesAnalytics isAdmin={false} />
                                    <EarningReports />
                                </Col>
                            </Row>
                            <Row>
                                <LatestTransactions />
                                <RecentOrders isAdmin={false} />
                            </Row>
                        </>
                    )}
                </Container>
            </div>
        </>
    );
};

export default Dashboard;