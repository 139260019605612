import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";
import { Card, CardBody, Col, Modal, ModalBody, Spinner } from "reactstrap";
import BACKEND_DOMAIN from "../../service/index";
import axios from "axios";

const LatestTransactions = () => {
    const authToken = useSelector((state) => state.Login.token);
    const [loading, setLoading] = useState(false);  
    const [transactions, setTransactions] = useState([]);
    const [isTransactionsListEmpty, setIsTransactionsListEmpty] = useState(false);

    const fetchTransactions = async ({token, isInitial = false}) => {
        const apiURL = `${BACKEND_DOMAIN}/wallets/merchant/transactions`;

        try {
            if (isInitial) setLoading(true); 
            const response = await axios.get(apiURL, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const transactionsList = response.data.results;
            if (transactionsList.length === 0) {
                setIsTransactionsListEmpty(true);
            }

            const formattedTransactions = transactionsList.map(transaction => {
                const dateObj = new Date(transaction.created_at);
                const formattedDate = `${dateObj.toLocaleDateString('en-GB').replace(/\//g, '-')} ${dateObj.toLocaleTimeString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit'
                })}`;
                return { ...transaction, formatted_created_at: formattedDate };
            });

            setTransactions(formattedTransactions);
        } catch (e) {
            console.error("Error fetching Transactions -> ", e);
        }
        finally {
            if (isInitial) setLoading(false); // Set loading to false once data is loaded
        }
    };

    useEffect(() => {
        fetchTransactions({token:authToken, isInitial:true});

                 // Set up polling every 10 seconds for subsequent data fetches without loading spinner
                 const intervalId = setInterval(() => {
                    fetchTransactions({token:authToken, isInitial:true});// No `true` argument, so `isInitial` is `false`
                }, 30000); // Poll every 10 seconds
       
                // Cleanup interval on component unmount
                return () => clearInterval(intervalId);

    }, [authToken]);

    const columns = useMemo(
        () => [
            {
                Header: "Transaction Code",
                accessor: "reference",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Sender",
                accessor: "sender_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Amount",
                accessor: "amount",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Transaction Fee",
                accessor: "fee",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Wallet Account",
                accessor: "wallet_account",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date",
                accessor: "formatted_created_at",
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <Col lg={12} className="mb-5 mb-md-0">
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Recent Transactions</h4>
                        <TableContainer
                            columns={columns || []}
                            data={transactions || []}
                            isPagination={false}
                            iscustomPageSize={false}
                            isBordered={false}
                            customPageSize={5}
                            isEmpty={isTransactionsListEmpty}
                            isEmptyContent="There are currently no transactions available"
                        />
                    </CardBody>
                </Card>
            </Col>

                           {/* Modal for Spinner */}
             <Modal isOpen={loading} centered>
                <ModalBody className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                    <div>Loading...</div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default LatestTransactions;
