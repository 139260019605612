// export default RecentOrders;
import React, { useMemo, useState, useEffect } from "react";
import TableContainer from "../../../components/Common/TableContainer";
import { Card, CardBody, Col } from "reactstrap";
import { useSelector } from "react-redux";
import BACKEND_DOMAIN from "../../../service";
import dateFormatter from "../../Orders/utilities/dateFormatter";

const RecentOrders = ({branchId}) => {
    const token = useSelector((state) => state.Login.token);

    const [orders, setOrders] = useState([]);
    const [menuOpen, setMenuOpen] = useState({});
    const [orderListIsEmpty, setOrderListIsEmpty] = useState(false);

    const fetchOrders = ({token,branchId}) => {
  
          const apiUrl = `${BACKEND_DOMAIN}/business/orders/merchant/list/${branchId}`;
        

        fetch(apiUrl, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((res) => {
                let data;
                data = res.orders;

                if (data.length === 0) {
                    setOrderListIsEmpty(true);
                }
                setOrders(data.reverse());
            })
            .catch((error) => {
                console.error("Error fetching orders:", error);
            });
    };

    // Call fetchOrders on initial render
    // useEffect(() => {
    //     fetchOrders();
    // }, [token]);

    useEffect(() => {
        if (branchId) {
            fetchOrders(branchId);
        }
    }, [token,branchId]);


    const merchantColumns = useMemo(
        () => [
            {
                Header: "OrderID",
                accessor: "order_number",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Customer",
                accessor: "customer_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Amount",
                accessor: "total_order_amount",
                Cell: ({ cell: { value } }) => {
                    return `KES ${value}`;
                },
                disableFilters: true,
                filterable: false,
            },

            {
                Header: "Order Type",
                accessor: "order_type",
                Cell: ({ cell: { value, row } }) => {
                    let typeIcon;
                    let tooltipContent;

                    if (value === "DELIVERY") {
                        typeIcon = <i className="mdi mdi-moped"></i>;
                        tooltipContent = row.original.order_type; // Replace 'name' with the actual field name
                    } else if (value === "RESERVE") {
                        typeIcon = <i className="mdi mdi-calendar-clock-outline"></i>;
                        tooltipContent = row.original.order_type;
                    } else if (value === "PICK-UP") {
                        typeIcon = <i className="mdi mdi-car-back"></i>;
                        tooltipContent = row.original.order_type;
                    } else if (value === "IN-STORE") {
                        typeIcon = <i className="mdi mdi-silverware-fork-knife"></i>;
                        tooltipContent = row.original.order_type;
                    }

                    return (
                        <React.Fragment>
                            <div>{tooltipContent}</div>
                        </React.Fragment>
                    );
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date",
                accessor: "created_at",
                Cell: ({ cell: { value } }) => {
                    const datePart = dateFormatter(value);
                    return datePart;
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Status",
                accessor: "payment_status",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }) => {
                    let statusBadge;
                    if (value === "PAID") {
                        statusBadge = <span className="badge bg-success-subtle text-success text-uppercase">PAID</span>;
                    } else if (value === "UNPAID") {
                        statusBadge = <span className="badge bg-danger-subtle text-danger text-uppercase">UNPAID</span>;
                    }
                    return <React.Fragment>{statusBadge}</React.Fragment>;
                },
            },
            {
                Header: "Stage",
                accessor: "status",
                Cell: ({ cell: { value } }) => {
                    let statusBadge, statusText;
                    if (value === "complete") {
                        statusBadge = (
                            <span className="badge bg-success-subtle text-success text-uppercase">Accepted</span>
                        );
                        statusText = "Accepted";
                    } else if (value === "rejected") {
                        statusBadge = (
                            <span className="badge bg-danger-subtle text-danger text-uppercase">Rejected</span>
                        );
                        statusText = "Rejected";
                    } else if (value === "pending") {
                        statusBadge = (
                            <span className="badge bg-warning-subtle text-warning text-uppercase">Pending</span>
                        );
                        statusText = "Pending";
                    }
                    return <React.Fragment>{statusBadge}</React.Fragment>;
                },
                disableFilters: true,
                filterable: false,
            },
        ],
        [menuOpen, orders]
    );

    const adminColumns = useMemo(
        () => [
            {
                Header: "OrderID",
                accessor: "order_number",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Merchant",
                accessor: "merchant_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Customer",
                accessor: "customer_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Amount",
                accessor: "total_order_amount",
                Cell: ({ cell: { value } }) => {
                    return `Ksh ${value}`;
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Order Type",
                accessor: "order_type",
                Cell: ({ cell: { value, row } }) => {
                    let typeIcon;
                    let tooltipContent;

                    console.log('order type ');

                    if (value === "DELIVERY") {
                        typeIcon = <i className="mdi mdi-moped"></i>;
                        tooltipContent = row.original.order_type; // Replace 'name' with the actual field name
                    } else if (value === "RESERVE") {
                        typeIcon = <i className="mdi mdi-calendar-clock-outline"></i>;
                        tooltipContent = row.original.order_type;
                    } else if (value === "PICK-UP") {
                        typeIcon = <i className="mdi mdi-car-back"></i>;
                        tooltipContent = row.original.order_type;
                    } else if (value === "IN-STORE") {
                        typeIcon = <i className="mdi mdi-silverware-fork-knife"></i>;
                        tooltipContent = row.original.order_type;
                    }

                    return (
                        <React.Fragment>
                            <div>{tooltipContent}</div>
                        </React.Fragment>
                    );
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date",
                accessor: "created_at",
                Cell: ({ cell: { value } }) => {
                    const datePart = dateFormatter(value);
                    return datePart;
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Status",
                accessor: "payment_status",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }) => {
                    let statusBadge;
                    if (value === "PAID") {
                        statusBadge = <span className="badge bg-success-subtle text-success text-uppercase">PAID</span>;
                    } else if (value === "UNPAID") {
                        statusBadge = <span className="badge bg-danger-subtle text-danger text-uppercase">UNPAID</span>;
                    }
                    return <React.Fragment>{statusBadge}</React.Fragment>;
                },
            },
            {
                Header: "Stage",
                accessor: "status",
                Cell: ({ cell: { value } }) => {
                    let statusBadge, statusText;
                    if (value === "complete") {
                        statusBadge = (
                            <span className="badge bg-success-subtle text-success text-uppercase">Accepted</span>
                        );
                        statusText = "Accepted";
                    } else if (value === "rejected") {
                        statusBadge = (
                            <span className="badge bg-danger-subtle text-danger text-uppercase">Rejected</span>
                        );
                        statusText = "Rejected";
                    } else if (value === "pending") {
                        statusBadge = (
                            <span className="badge bg-warning-subtle text-warning text-uppercase">Pending</span>
                        );
                        statusText = "Pending";
                    }
                    return <React.Fragment>{statusBadge}</React.Fragment>;
                },
                disableFilters: true,
                filterable: false,
            },
        ],
        [menuOpen, orders]
    );

    return (
        <React.Fragment>
            <Col lg={12} className="mb-5 mb-md-0">
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Recent Orders</h4>

                        <TableContainer
                            columns={merchantColumns || []}
                            data={orders || []}
                            isPagination={true}
                            iscustomPageSize={false}
                            isBordered={false}
                            customPageSize={5}
                            isEmpty={orderListIsEmpty}
                            isEmptyContent="There are no orders available currently."
                        />
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default RecentOrders;
