import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Alert,Badge } from "reactstrap";
import SimpleBar from "simplebar-react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const NotificationDropdown = () => {
    const [menu, setMenu] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState(false); // State to manage modal expansion
    const [successRead, setSuccessfulRead] = useState('');
    const [notificationsLength, setNotificationsLenth ] = useState(0);

    const { t } = useTranslation();

    const authToken = useSelector((state) => state.Login.token); // Access the token from Redux

    useEffect(() => {
        fetchNotifications();
    }, []);

    const toggle = () => {
        setMenu(!menu);
    };

    const toggleViewMore = () => {
        setExpanded(!expanded); // Toggle the expanded state
    };

    const markNotificationRead = async (notificationId) => {
        console.log('notification ', notificationId);
        try {
            const response = await axios.post(
                `https://backoffice.route.money/api/v1/business/merchant/notification/mark-as-read/${notificationId}`,
                {},  // Empty payload as you're not sending any body data
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,  // Correct placement of headers
                    },
                }
            );
            if (response.data && response.data.message) {
                setSuccessfulRead(response.data.message);

                setTimeout(() => {
                    setSuccessfulRead('');
                }, 3000);
                fetchNotifications();
            }
            setLoading(false);
        } catch (error) {
            console.error("Error updating notifications:", error);
            setLoading(false);
        }
    };
    

    // const markNotificationRead = async (notificationId) =>{
    //     console.log('notification ',notificationId)
    //     try {
    //         const response = await axios.post(
    //             `https://backoffice.route.money/api/v1/business/merchant/notification/mark-as-read/${notificationId}`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${authToken}`,
    //                 },
    //             }
    //         );
    //         if (response.data && response.data) {
    //             setSuccessfulRead(response.data.message);
    //         }
    //         setLoading(false);
    //     } catch (error) {
    //         console.error("Error updating notifications:", error);
    //         setLoading(false);
    //     }  
    // }

    const fetchNotifications = async () => {
        try {
            const response = await axios.get(
                "https://backoffice.route.money/api/v1/business/merchant/notifications",
                {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                }
            );
            if (response.data && response.data.data) {
               
             const notificationLength=(response.data.data).length;
             setNotificationsLenth(notificationLength);

                setNotifications(response.data.data);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error fetching notifications:", error);
            setLoading(false);
        }
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
          
                <DropdownToggle
                    tag="button"
                    className="btn header-item noti-icon waves-effect position-relative mr-7"
                    id="page-header-notifications-dropdown"
                >
                    {/* <i className="ri-notification-3-line"></i>
                                        {notificationsLength > 0 && (
                        <Badge 
                        bg="danger" 
                        pill 
                        className="position-absolute mt-3 mr-3 start-100 translate-middle"
                        style={{ fontSize: '0.52rem', verticalAlign: 'super' }}
                        >
                        {notificationsLength}
                        </Badge>
                    )} */}

                    <i class="fas fa-bell fa-2x" style={{verticalAlign:"sub"}}></i>
                    <span class="badge rounded-pill badge-notification bg-danger" style={{verticalAlign:'super'}}>{notificationsLength}</span>
                   
                </DropdownToggle>
                
                <DropdownMenu
                    className="dropdown-menu-end dropdown-menu-lg p-0"
                    aria-labelledby="page-header-notifications-dropdown"
                    style={{ maxHeight: expanded ? "500px" : "300px" }} // Adjust height based on expanded state
                >
                    <div className="p-3">
                        <Row className="align-items-center">
                            <Col>
                                <h6 className="m-0"> {t("Notifications")} </h6>
                            </Col>
                            <div className="col-auto">
                                <Link to="#" className="small"> {t("View All")}</Link>
                            </div>
                        </Row>
                        <Row className="align-items-center">
                        {successRead && <Alert color="primary">{successRead}</Alert>}
                        </Row>
                    </div>
                    <SimpleBar style={{ maxHeight: expanded ? "400px" : "200px" }}>
                        {loading ? (
                            <div className="text-center p-3">
                                <i className="ri-loader-4-line ri-spin"></i> {t("Loading...")}
                            </div>
                        ) : (
                            notifications.length > 0 ? (
                                notifications.map((notification) => (
                                    <Link to="#" key={notification.id}  onClick={() => markNotificationRead(notification.id)} className="text-reset notification-item">
                                        <div className="d-flex">
                                            <div className="avatar-xs me-3">
                                                <span
                                                    className={`avatar-title ${
                                                        notification.status === "unread"
                                                            ? "bg-primary"
                                                            : "bg-success"
                                                    } rounded-circle font-size-16`}
                                                >
                                                    <i className="ri-notification-3-line"></i>
                                                </span>
                                            </div>
                                            <div className="flex-1">
                                                <h6 className="mt-0 mb-1">{notification.title}</h6>
                                                <div className="font-size-12 text-muted">
                                                    <p className="mb-1">{notification.description}</p>
                                                    <p className="mb-0">
                                                        <i className="mdi mdi-clock-outline"></i>{" "}
                                                        {new Date(notification.date).toLocaleString()}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                ))
                            ) : (
                                <div className="text-center p-3">
                                    {t("No notifications available")}
                                </div>
                            )
                        )}
                    </SimpleBar>
                    <div className="p-2 border-top">
                        <Link
                            to="#"
                            className="btn btn-sm btn-link font-size-14 btn-block text-center"
                            onClick={toggleViewMore} // Toggle modal height on click
                        >
                            <i className="mdi mdi-arrow-right-circle me-1"></i>
                            {expanded ? t("View Less") : t("View More")}
                        </Link>
                    </div>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default NotificationDropdown;
