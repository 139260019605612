import React, { useEffect, useMemo, useState } from "react";
import TableContainer from "../../../components/Common/TableContainer";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, Container,Spinner, Modal, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";
import convertDate from "../../Orders/utilities/dateFormatter";
import axios from 'axios';
import BACKEND_DOMAIN from "../../../service";

const Customers = () => {
    const [staff, setStaff] = useState([]);
    const authToken = useSelector((state) => state.Login.token);
    const [loading, setLoading] = useState(false);  // Added loading state

const staffUrl=`${BACKEND_DOMAIN}/admin/users/staff`
  useEffect(() => {
    if (authToken) {
      const fetchStaff = async (isInitial = false) => {
        try {
            if (isInitial) setLoading(true); 
          const response = await axios.get(staffUrl, {
            headers: {
              Authorization: `Bearer ${authToken}`,
              'Content-Type': 'application/json',
            },
          });
          setStaff(response.data.results);
        } catch (error) {
          console.error("Error fetching roles:", error);
        } finally{
            if (isInitial) setLoading(false); 
        }
      };


      fetchStaff();
  
    }
  }, [authToken]);

    // const fetchCustomers = async (token) => {
    //      const allUsers = await adminStaffs;

    //      setStaff(allUsers);
    // };

    // useEffect(() => {
    //     fetchCustomers(authToken);
    // }, [authToken]);

    const columns = useMemo(
        () => [
            {
                Header: "First Name",
                accessor: "first_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Last Name",
                accessor: "last_name",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Email",
                accessor: "email",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Phone No.",
                accessor: "phone_number",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Date Created",
                accessor: "created_at",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }) => {
                    let modifiedDate = convertDate(value);
                    return <React.Fragment>{modifiedDate}</React.Fragment>;
                },
            },
            {
                Header: "Status",
                accessor: "is_active",
                disableFilters: true,
                filterable: false,
                Cell: ({ cell: { value } }) => {
                    let isActive;
                    if (value === true) {
                        isActive = "Active";
                    } else {
                        isActive = "Inactive";
                    }
                    return <>{isActive}</>;
                },
            },
        ],
        []
    );

    const breadcrumbItems = [
        { title: "Users", link: "/" },
        { title: "Staff", link: "#" },
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Staff" breadcrumbItems={breadcrumbItems} />
                    <Card>
                        <CardBody>
                            <TableContainer
                                columns={columns || []}
                                data={staff || []}
                                isPagination={false}
                                isGlobalFilter={true}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={10}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
                           {/* Modal for Spinner */}
             <Modal isOpen={loading} centered>
                <ModalBody className="text-center">
                    <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
                    <div>Loading...</div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default Customers;
