import React, { useState } from "react";
import { Container, Row, Col, Card, CardBody, Label, Button, Alert } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//import { uploadBranchRequest, uploadBranchSuccess, uploadBranchFailure } from "../../thunks";
import Select from "react-select";
import walletTypes from "../../utilities/walletTypes";
import businessTypes from "../../../Authentication/utilities/businessTypes";
import { specifiedBusinessTypes } from "../../../Authentication/utilities/businessTypes";
import BACKEND_DOMAIN from "../../../../service";

const MerchantBranchAdd = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [branch, setBranch] = useState({
        sub_name: "",
        sub_contact_person: "",
        sub_phone_number: "",
        sub_alt_phone_number: "",
        sub_location: "",
        sub_email: "",
        sub_profile_image: null,
        sub_cover_image: null,
        sub_password: "",
        sub_wallet_balance: "",
        type:"",
        specific_type:"",
        sub_password_confirm:"",
    
    });

    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedWalletType, setSelectedWalletType] = useState(null);
    const [selectedBusinessType, setSelectedBusinessType] = useState(null);
    const [selectedSpecBusinessType, setSelectedSpecBusinessType] = useState(null);
    const [specBusinessTypeOptions, setSpecBusinessTypeOptions] = useState([]);
    const [profileImage, setProfileImage] = useState(null);
    const [coverImage, setCoverImage] = useState(null);
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const [creatingError, setCreatingError] = useState(null);

    const token = useSelector((state) => state.Login.token);

    const onWalletTypeChange = (selectedWalletType) => {
        setSelectedWalletType(selectedWalletType);

        setBranch((prevBranch) => ({
            ...prevBranch,
            sub_wallet_balance: selectedWalletType.value,
        }));
    };

    const onBusinessTypeChange = (selectedBusinessType) => {
        setSelectedBusinessType(selectedBusinessType);
    
        // Find index of selected category
        const businessTypeIndex = specifiedBusinessTypes.findIndex(
            (bType) => Object.keys(bType)[0] === `${selectedBusinessType.label}`
        );
        setSpecBusinessTypeOptions(specifiedBusinessTypes[businessTypeIndex][selectedBusinessType.label]);
    
        setSelectedSpecBusinessType(null);
    
        // Update allFormData directly
        setBranch((prevBranch) => ({
            ...prevBranch,
            specific_type: "", // Assuming you want to clear specific_type
            type: selectedBusinessType.value,
        }));
    };

    const onSpecBusinessTypeChange = (selectedSpecBusinessType) => {
        setSelectedSpecBusinessType(selectedSpecBusinessType);

        setBranch((prevBranch) => ({
            ...prevBranch,
             specific_type: selectedSpecBusinessType.value,
     }));
 };

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;

        // If the input is a file, get the file object
        const file = type === "file" ? e.target.files[0] : null;

        setBranch((prevBranch) => ({
            ...prevBranch,
            [name]: type === "file" ? file : value,
        }));
    };
 

    // // Personalized validation function for the wallet type:
    // const formValidator = () => {
    //     if (!selectedWalletType) {
    //         setCreatingError("Please select a wallet type.");
    //         setTimeout(() => {
    //             setCreatingError(null);
    //         }, 3000);
    //         return false;
    //     }

    //     return true;
    // };

    // const handleFormSubmit = async (event) => {
    //     event.preventDefault();

    //    // if (!formValidator()) return;

    //     setIsLoading(true);

    //     try {
     
    //     const jsonData = {
    //         name: branch.sub_name,
    //         contact_person: branch.sub_contact_person,
    //         phone_number: branch.sub_phone_number,
    //         alt_phone_number: branch.sub_alt_phone_number,
    //         email: branch.sub_email,
    //         location: branch.sub_location,
    //         type: branch.type,
    //         specific_type: branch.specific_type,
    //         password:branch.sub_password,
    //         confirmPassword:branch.sub_password_confirm,
    //         profile_image:branch.sub_profile_image,
    //         cover_image:branch.sub_cover_image,
    //     };

    //     // Append file objects
    
    //         console.log('branch data',jsonData);
    //          const apiurl = `https://backoffice.route.money/api/v1/business/merchant/branch/create/`;
    //          const response = await fetch(apiurl, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${token}`,
    //             },
    //             body: JSON.stringify(jsonData),
    //         });

    
    //         if (!response.ok) {
    //             throw new Error("Error adding  a branch");
    //         }
    
    //         const data = await response.json();
    //         console.log('successfully created branch ',data);
    //         setSuccessMessage("Branch Created successfully!");
    //         setIsLoading(false);
    //         // setBranch({
    //         //     sub_name: "",
    //         //     sub_contact_person: "",
    //         //     sub_phone_number: "",
    //         //     sub_alt_phone_number: "",
    //         //     sub_location: "",
    //         //     sub_email: "",
    //         //     sub_kra_pin: "",
    //         //     sub_profile_image: null,
    //         //     sub_cover_image: null,
    //         //     sub_password: "",
    //         //     sub_wallet_balance: "",
    //         //     type: "",
    //         //     specific_type: "",
    //         //     surname: "",
    //         //     first_name: "",
    //         //     last_name: "",
    //         // });

    //         setTimeout(() => {
    //             setSuccessMessage("");
    //             navigate("/route-money/merchant/branch/list");
    //         }, 3000);
         
    //     } catch (error) {
    //         console.log('error creating branch ',error);
    //         setErrorMessage("Failed, trty again!");
    //         setIsLoading(false);
    //         // setBranch({
    //         //     sub_name: "",
    //         //     sub_contact_person: "",
    //         //     sub_phone_number: "",
    //         //     sub_alt_phone_number: "",
    //         //     sub_location: "",
    //         //     sub_email: "",
    //         //     sub_kra_pin: "",
    //         //     sub_profile_image: null,
    //         //     sub_cover_image: null,
    //         //     sub_password: "",
    //         //     sub_wallet_balance: "",
    //         //     type: "",
    //         //     specific_type: "",
    //         //     surname: "",
    //         //     first_name: "",
    //         //     last_name: "",
    //         // });

    //         setTimeout(() => {
    //             setErrorMessage("");
    //         }, 3000);
    //     }
          
    // };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
       // if (!formValidator()) return;

        setIsLoading(true);

        const formData = new FormData();
        formData.append("name", branch.sub_name);
        formData.append("contact_person", branch.sub_contact_person);
        formData.append("phone_number", branch.sub_phone_number);
        formData.append("alt_phone_number", branch.sub_alt_phone_number);
        formData.append("email", branch.sub_email);
        formData.append("location", branch.sub_location);
        formData.append("type", branch.type);
        formData.append("specific_type", branch.specific_type);
        formData.append("password", branch.sub_password);
        formData.append("confirmPassword", branch.sub_password_confirm);
        if (branch.sub_profile_image) {
            formData.append("profile_image", branch.sub_profile_image);
        }
        if (branch.sub_cover_image) {
            formData.append("cover_image", branch.sub_cover_image);
        }
        let errorMessages;
        try {
            //const apiurl = `${BACKEND_DOMAIN}/business/merchant/branch/create/`;
            const apiUrl=`https://backoffice.route.money/api/v1/business/merchant/branch/create/`;
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

                 const data = await response.json()
            
            if (!response.ok) {
                errorMessages  = data.errors;
                throw new Error("Error adding a branch",data.errors);
              //  setErrorMessage("Error adding a branch!",response.errors);
            }

       
            setSuccessMessage("Branch Created successfully!");
            setIsLoading(false);

            setTimeout(() => {
                setSuccessMessage("");
                navigate("/route-money/merchant/branch/list");
            }, 3000);

        } catch (errors) {
            console.error('Error creating branch:',errors);
          
            console.error("Error adding a branch:", errorMessages);

            // Collect all error messages into an array
            const errorArray = Object.keys(errorMessages).map((field) => {
                return `${field}: ${errorMessages[field].join(", ")}`;
            });

            // Join the array into a single string with line breaks or commas
            setErrorMessage(errorArray.join(" , "));
          //  setErrorMessage("Failed, try again!");
            setIsLoading(false);

            setTimeout(() => {
                setErrorMessage("");
            }, 3000);
        }
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Add Branch"
                        breadcrumbItems={[
                            { title: "Branches", link: "/branch" },
                            { title: "Add Branch", link: "#" },
                        ]}
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">Add Branch</h4>
                                    {successMessage && <Alert color="success">{successMessage}</Alert>}
                                    
                                   {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

                                    <AvForm onValidSubmit={handleFormSubmit} enctype="multipart/form-data">
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subName">
                                                        Branch Name
                                                    </Label>
                                                    <AvField
                                                        name="sub_name"
                                                        id="subName"
                                                        type="text"
                                                        placeholder="Enter Branch Name"
                                                        errorMessage="Enter Branch Name"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subLocation">
                                                        Location
                                                    </Label>
                                                    <AvField
                                                        name="sub_location"
                                                        id="subLocation"
                                                        type="text"
                                                        placeholder="Enter Location"
                                                        errorMessage="Enter Location"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subContactPerson">
                                                        Contact Person
                                                    </Label>
                                                    <AvField
                                                        name="sub_contact_person"
                                                        id="subContactPerson"
                                                        type="text"
                                                        placeholder="Enter Contact Person"
                                                        errorMessage="Enter Contact person"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subEmail">
                                                        Email
                                                    </Label>
                                                    <AvField
                                                        name="sub_email" // Updated to match the API field name
                                                        id="subEmail"
                                                        type="email"
                                                        placeholder="Enter Email"
                                                        errorMessage="Enter Email"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                          
                                        </Row>
                                
                                        <Row>
                                        <Col lg="6">
                                            <div className="mb-3">
                                                <Label>
                                                    General Business Type{" "}
                                                    <span className="text-danger">*</span>
                                                </Label>

                                                <Select
                                                    placeholder="Select or Type to filter..."
                                                    options={businessTypes}
                                                    value={selectedBusinessType}
                                                    onChange={onBusinessTypeChange}
                                                    className={`form-control`}
                                                 
                                                />
                                                  
                                            </div>
                                        </Col>

                                        <Col lg="6">
                                        <div className="mb-3">
                                            <Label>
                                                Specific Business Type{" "}
                                                <span className="text-danger">*</span>
                                            </Label>

                                            <Select
                                                placeholder="Select or Type to filter..."
                                                options={
                                                    !selectedBusinessType
                                                        ? [
                                                                {
                                                                    value: "",
                                                                    label: "Select General Type First",
                                                                },
                                                            ]
                                                        : specBusinessTypeOptions
                                                }
                                                value={selectedSpecBusinessType}
                                                onChange={onSpecBusinessTypeChange}
                                                className={`form-control`}
                                               
                                            />
                                               
                                        </div>
                                    </Col>
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subProfileImage">
                                                        Branch Profile Image
                                                    </Label>
                                                    <AvField
                                                        name="sub_profile_image"
                                                        id="subProfileImage"
                                                        type="file"
                                                        errorMessage="Select Profile Image"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                                                                        <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subProfileImage">
                                                        Branch Cover Page
                                                    </Label>
                                                    <AvField
                                                        name="sub_cover_image"
                                                        id="subCoverImage"
                                                        type="file"
                                                        errorMessage="Select Cover Image"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>

                                           
                                        </Row>
                                        <Row>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="phoneNumber">
                                                        Phone Number
                                                    </Label>
                                                    <AvField
                                                        name="sub_phone_number"
                                                        id="phoneNumber"
                                                        type="text"
                                                        placeholder="Enter Phone Number"
                                                        errorMessage="Enter Phone Number"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="altPhoneNumber">
                                                        Alternative Phone Number
                                                    </Label>
                                                    <AvField
                                                        name="sub_alt_phone_number"
                                                        id="altPhoneNumber"
                                                        type="text"
                                                        placeholder="Enter Alternative Phone Number"
                                                        errorMessage="Enter Alternative Phone Number"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subPassword">
                                                        Password
                                                    </Label>
                                                    <AvField
                                                        name="sub_password"
                                                        id="subPassword"
                                                        type={passwordVisible ? "text" : "password"}
                                                        placeholder="Enter Password"
                                                        errorMessage="Enter Password"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />
                                                <div className="d-flex justify-content-end mt-1">
                                                    <small
                                                        className="text-muted cursor-pointer"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {passwordVisible ? "Hide" : "Show"}
                                                    </small>
                                                </div>
                                                </div>
                                            </Col>

                                            <Col md="6">
                                                <div className="mb-3">
                                                    <Label className="form-label" htmlFor="subEmail">
                                                        Confirm Password
                                                    </Label>
                                                    <AvField
                                                        name="sub_password_confirm" // Updated to match the API field name
                                                        id="subPasswordConfirm"
                                                        type={passwordVisible ? "text" : "password"}
                                                        placeholder="Confirm Password"
                                                        errorMessage="Confirm password"
                                                        className="form-control"
                                                        onChange={handleInputChange}
                                                        validate={{ required: { value: true } }}
                                                    />

                                                <div className="d-flex justify-content-end mt-1">
                                                    <small
                                                        className="text-muted cursor-pointer"
                                                        onClick={togglePasswordVisibility}
                                                    >
                                                        {passwordVisible ? "Hide" : "Show"}
                                                    </small>
                                                </div>
                                                </div>
                                            </Col>

                                        </Row>
                                        {creatingError && <Alert color="danger">{creatingError}</Alert>}
                                        <Button color="primary" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <>
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                    <span className="ms-2">Adding..</span>
                                                </>
                                            ) : (
                                                "Add Branch"
                                            )}
                                        </Button>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MerchantBranchAdd;